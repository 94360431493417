import React, { useState } from 'react';
import GridRow from '../../../ui/GridRow';
import GridCol from '../../../ui/GridCol';
import Filters from '../../../pages/ProductAll/modules/Filters';
import Table from '../../../ui/Table';
import i18n from '../../../utils/i18n';
import { pathsApi } from '../../../router/paths';
import { useFetchGet } from '../../../utils/fetch/useFetchGet';
import { IPage } from '../../../utils/types';
import { IProductShort } from '../../../pages/ProductAll/types';

interface IProductListing {
    selectProduct: (product: IProductShort) => void;
}

const ProductListing = ({ selectProduct }: IProductListing) => {
    const [searchState, setSearchState] = useState<string | undefined>();
    const { data, loadMore } = useFetchGet<IPage<IProductShort>>({ path: pathsApi.productAll, search: searchState });

    return (
        <GridRow gutter={8}>
            <GridCol col={12}>
                <Filters submitSearchString={setSearchState} />
            </GridCol>
            <GridCol col={12}>
                <Table
                    widths={[10, 33]}
                    pagination={data && { current: data.current, total: data.total, loadMore }}
                    headers={[i18n.general.brand, i18n.general.name, i18n.general.category]}
                    data={data?.content.map((item) => ({
                        items: [item.brand?.name, item.name, item.category.name],
                        id: item.id,
                        onClick: () => selectProduct(item),
                    }))}
                />
            </GridCol>
        </GridRow>
    );
};

export default ProductListing;

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import s from '../styles/imagesUpload.module.scss';
import { ButtonVariationEnum } from '../../../ui/Button/types';
import i18n from '../../../utils/i18n';
import Button from '../../../ui/Button';
import { FetchApi } from '../../../utils/fetch/fetchApi';
import { pathsApi } from '../../../router/paths';
import { FileUploadType } from '../types';
import { AppDispatch } from '../../../state';
import { setNotificationSuccess } from '../../../state/reducers/notification';

interface IDeleteButton {
    src: string;
    type: FileUploadType;
    handleDelete: () => void;
}

const DeleteButton = ({ handleDelete, src, type }: IDeleteButton) => {
    const [isLoading, setLoading] = useState<boolean>(false);
    const dispatch = useDispatch<AppDispatch>();

    const handleClick = async () => {
        if (isLoading) return;

        setLoading(true);
        const data = {
            fileName: src,
            type,
        };
        const result = await FetchApi.post<{ success: boolean }>(pathsApi.deleteFile, data);
        if (result?.success) {
            dispatch(setNotificationSuccess(i18n.general.successFullyDeleted));
            handleDelete();
        }
        setLoading(false);
    };

    return (
        <Button
            isSmall
            className={s.deleteButton}
            variation={ButtonVariationEnum.OUTLINE}
            isLoading={isLoading}
            onClick={handleClick}
        >
            {i18n.general.delete}
        </Button>
    );
};

export default DeleteButton;

import { useEffect } from 'react';
import { useConfig } from '../../../components/Config/config';

export const useFiltersData = () => {
    const { config, getCategoryFilters } = useConfig();

    useEffect(() => {
        if (!config.categoryFilters) {
            getCategoryFilters();
        }
    }, []);

    return config.categoryFilters;
};

import React from 'react';
import Body from '../../ui/Body/body';
import i18n from '../../utils/i18n';
import GridRow from '../../ui/GridRow';
import GridCol from '../../ui/GridCol';
import ButtonLink from '../../ui/Button/buttonLink';
import { paths, pathsApi } from '../../router/paths';
import { ButtonVariationEnum } from '../../ui/Button/types';
import { useFetchGet } from '../../utils/fetch/useFetchGet';
import { IPage } from '../../utils/types';
import { IProductShort } from './types';
import Table from '../../ui/Table';
import Filters from './modules/Filters';
import { TagEnum } from '../../ui/Tag/types';
import { boolToReadable } from '../../utils/boolUtil';
import Tag from '../../ui/Tag';

const ProductAll = () => {
    const { data, loadMore } = useFetchGet<IPage<IProductShort>>({ path: pathsApi.productAll, watchPage: true });

    return (
        <Body title={i18n.menu.products} isLoading={!data}>
            <GridRow gutter={8}>
                <GridCol col={12}>
                    <ButtonLink href={paths.productNew} variation={ButtonVariationEnum.SOFT}>
                        {i18n.menu.productNew}
                    </ButtonLink>
                </GridCol>
                <GridCol col={12}>
                    <Filters />
                </GridCol>
                <GridCol col={12}>
                    <Table
                        widths={[10, 30, 50, 10]}
                        pagination={data && { current: data.current, total: data.total, loadMore }}
                        headers={[i18n.general.brand, i18n.general.name, i18n.general.category, i18n.general.active]}
                        data={data?.content.map((item) => ({
                            items: [
                                item.brand?.name,
                                item.name,
                                item.category.name,
                                <Tag type={!!item.deletedAt ? TagEnum.RED : TagEnum.GREEN}>
                                    {boolToReadable(!item.deletedAt)}
                                </Tag>,
                            ],
                            id: item.id,
                            link: paths.product + item.id,
                        }))}
                    />
                </GridCol>
            </GridRow>
        </Body>
    );
};

export default ProductAll;

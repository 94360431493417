import React, { useState } from 'react';
import { Control, useController } from 'react-hook-form';
import PriceAttrBlock from '../../PriceAttr';
import { IPriceAttr } from '../../../utils/types';
import Tag from '../../../ui/Tag';
import TextItem from '../../../ui/TextItem/textItem';
import i18n from '../../../utils/i18n';
import s from '../styles/priceBlock.module.scss';
import Button from '../../../ui/Button';
import { ButtonVariationEnum } from '../../../ui/Button/types';
import Popup from '../../../ui/Popup';
import GridRow from '../../../ui/GridRow';
import { useConfig } from '../../Config/config';

interface IAttr {
    control: Control;
    name: string;
}

const Attr = ({ control, name }: IAttr) => {
    const {
        config: { priceAttrGroups },
    } = useConfig();
    const [isPopupOpen, togglePopup] = useState<boolean>(false);
    const { field } = useController({
        control: control,
        defaultValue: [],
        name: name + '.attrList',
    });

    const updateAttr = (values: Array<IPriceAttr>) => {
        field.onChange(values);
        togglePopup(false);
    };

    return (
        <div>
            <GridRow column gutter={8}>
                <TextItem label={i18n.price.attr}>
                    <div className={s.attrList}>
                        {field?.value?.length
                            ? field.value.map((attr: IPriceAttr) => {
                                  const group = priceAttrGroups.find((group) =>
                                      group.items.some((item) => item.id === attr.id),
                                  );
                                  return <Tag key={attr.id}>{group?.value + ': ' + attr.value}</Tag>;
                              })
                            : '-'}
                    </div>
                </TextItem>
                <Button
                    variation={ButtonVariationEnum.SOFT}
                    isSmall
                    onClick={() => {
                        togglePopup(true);
                    }}
                >
                    {i18n.price.updateAttr}
                </Button>
            </GridRow>
            {isPopupOpen && (
                <Popup
                    title={i18n.price.attr}
                    close={() => {
                        togglePopup(false);
                    }}
                >
                    <PriceAttrBlock save={updateAttr} initValue={field.value} />
                </Popup>
            )}
        </div>
    );
};

export default Attr;

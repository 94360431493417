import { useEffect, useState } from 'react';
import { FetchApi } from './fetchApi';
import { useLocation } from 'react-router-dom';

interface IUseFetchGet<T> {
    defaultValue?: T;
    path: string;
    search?: string;
    watchPage?: boolean;
}

export const useFetchGet = <T>({ path, watchPage, defaultValue, search }: IUseFetchGet<T>) => {
    const [data, setData] = useState<T | undefined>(defaultValue);
    const location = useLocation();

    const fetchData = async () => {
        if (defaultValue) return;

        const searchParams = watchPage ? location.search : search ? '?' + search : '';

        const result = await FetchApi.get<T>(path + searchParams);
        if (result) {
            setData(result);
        }
    };

    const reload = async () => {
        setData(undefined);
        await fetchData();
    };

    const loadMore = async () => {
        // @ts-ignore
        if (!data?.content || data.current + 1 === data.total) return;

        const searchParams = new URLSearchParams(location.search);
        // @ts-ignore
        searchParams.set('page', data.current + 2);

        const result = await FetchApi.get<T>(path + '?' + searchParams.toString());
        if (result) {
            setData({
                ...result,
                // @ts-ignore
                content: [...data.content, ...result.content],
            });
        }
    };

    useEffect(() => {
        fetchData();
    }, [location.search, path, search]);

    return { data, reload, loadMore, setData };
};

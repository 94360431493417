import React, { useMemo } from 'react';
import { Control, useFieldArray, useFormState, useWatch } from 'react-hook-form';
import { useFiltersData } from '../../hooks/useFiltersData';
import Loading from '../../../../ui/Loading';
import GridRow from '../../../../ui/GridRow';
import ToggleButton from '../../../../ui/ToggleButton/ToggleButton';
import GridCol from '../../../../ui/GridCol';
import { GridAlignEnum } from '../../../../ui/GridRow/GridRow';
import i18n from '../../../../utils/i18n';
import { IconEnum } from '../../../../ui/Icon';
import Details from '../../../../ui/Details/details';
import NotificationInline, { NotificationTypeEnum } from '../../../../ui/NotificationInline';
import { ICategoryFiltersOption } from '../../../../components/Config/types';

const CategoryFilters = ({ control }: { control: Control }) => {
    const filtersData = useFiltersData();
    const category = useWatch({ name: 'category', control });

    const filteredItems = useMemo(() => {
        if (!filtersData || !category) return [];

        return filtersData.filter((item) => item.categories.includes(category.id));
    }, [filtersData, category]);

    const { fields, append, remove } = useFieldArray({
        control,
        keyName: 'formId',
        rules: {
            validate: (formActiveItems) => {
                if (!formActiveItems.length && filteredItems.length) return false;

                const formActiveItemsId = (formActiveItems as Array<{ id: number }>).map((item) => item.id);

                return filteredItems.every((group) => {
                    return group.items.some((groupItem: { id: number }) => formActiveItemsId.includes(groupItem.id));
                });
            },
        },
        name: 'filterItemList',
    });

    const { errors } = useFormState({ control });
    const hasError = !!errors.filterItemList;

    if (!filtersData) return <Loading />;

    const getValue = (id: number) => {
        return fields.some((item) => item.id === id);
    };

    const handleSelect = (state: boolean, changedItem: ICategoryFiltersOption) => {
        if (state) {
            append(changedItem);
        } else {
            // eslint-disable-next-line array-callback-return
            const indexToRemove = fields.findIndex((item) => item.id === changedItem.id);
            if (indexToRemove >= 0) {
                remove(indexToRemove);
            }
        }
    };

    return (
        <Details label={i18n.product.filters} icon={IconEnum.FILTER} error={hasError}>
            <GridRow gutter={8} alignRow={GridAlignEnum.TOP}>
                {hasError && (
                    <GridCol col={12}>
                        <NotificationInline type={NotificationTypeEnum.ERROR} text={i18n.product.chooseEveryFilter} />
                    </GridCol>
                )}
                {filteredItems.map((group) => {
                    return (
                        <GridCol col={3} key={group.id}>
                            <GridRow column gutter={4}>
                                <p>{group.name}</p>
                                {group.items.map((item) => {
                                    return (
                                        <ToggleButton
                                            key={item.id}
                                            value={getValue(item.id)}
                                            onClick={(state) => {
                                                handleSelect(state, item);
                                            }}
                                            label={item.name}
                                        />
                                    );
                                })}
                            </GridRow>
                        </GridCol>
                    );
                })}
            </GridRow>
        </Details>
    );
};

export default CategoryFilters;

export interface IButtonCommon {
    isSmall?: boolean;
    variation?: ButtonVariationEnum;
}

export enum ButtonVariationEnum {
    OUTLINE,
    SOFT,
    SIMPLE,
    ERROR,
}

import { pathsApi } from '../../router/paths';
import { parse as parseCookie } from 'cookie';
import state from '../../state';
import { setNotificationError } from '../../state/reducers/notification';

const API_PREFIX = '/myapi';
const API_HOST = location.origin;
const X_CSRF_TOKEN = 'x-csrf-token';
const X_CSRF_COOKIE = 'csrftoken';

const sendError = (e: string) => {
    state.dispatch(setNotificationError(e));
};

export const FetchApi = {
    get: async <T>(path: string): Promise<T | undefined> => {
        try {
            const response = await fetch(API_HOST + API_PREFIX + path, {
                method: 'GET',
                headers: {
                    'Accept-Content': 'application/json',
                },
            });
            if (response.status === 400) {
                const responseMessage = await response.json();
                const message = responseMessage.message;
                sendError(message || response.statusText);
                return;
            }
            if (response.ok) {
                return await response.json();
            }
            sendError(response.statusText);
        } catch (e) {
            sendError(e.message);
        }
    },
    post: async <T>(path: string, body: any): Promise<T | undefined> => {
        try {
            const cookies = parseCookie(document.cookie);
            const response = await fetch(API_HOST + API_PREFIX + path, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept-Content': 'application/json',
                    [X_CSRF_TOKEN]: cookies[X_CSRF_COOKIE],
                },
                body: JSON.stringify(body),
            });
            debugger;
            if (response.status === 400) {
                const responseMessage = await response.json();
                const message = responseMessage.message;
                sendError(message || response.statusText);
                return;
            }
            if (response.ok) {
                return await response.json();
            }
            sendError(response.statusText);
        } catch (e) {
            sendError(e.message);
        }
    },
    uploadFile: async <T>(body: any): Promise<T | undefined> => {
        try {
            const cookies = parseCookie(document.cookie);
            const response = await fetch(API_HOST + API_PREFIX + pathsApi.uploadFile, {
                body: body,
                headers: {
                    'Accept-Content': 'application/json',
                    [X_CSRF_TOKEN]: cookies[X_CSRF_COOKIE],
                },
                method: 'POST',
            });
            if (response.status === 400) {
                const responseMessage = await response.json();
                const message = responseMessage.message;
                sendError(message || response.statusText);
                return;
            }
            if (response.ok) {
                return await response.json();
            }
            sendError(response.statusText);
        } catch (e) {
            sendError(e.message);
        }
    },
    postLogin: async (path: string, body: any): Promise<Response | undefined> => {
        const cookies = parseCookie(document.cookie);
        const response = await fetch(API_HOST + API_PREFIX + path, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept-Content': 'application/json',
                [X_CSRF_TOKEN]: cookies[X_CSRF_COOKIE],
            },
            body: JSON.stringify(body),
        });
        if (response.ok) {
            return response;
        }
    },
};

import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Logo from '../../ui/Logo';
import GridRow from '../../ui/GridRow';
import Input from '../../ui/Input';
import i18n from '../../utils/i18n';
import Button from '../../ui/Button';
import { FetchApi } from '../../utils/fetch/fetchApi';
import NotificationInline, { NotificationTypeEnum } from '../../ui/NotificationInline';
import s from './styles/login.module.scss';

const Login = () => {
    const { control, handleSubmit } = useForm();
    const [hasError, setError] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(false);

    const submit = handleSubmit(async (data) => {
        setLoading(true);
        setError(false);
        const response = await FetchApi.postLogin('/login', data);
        if (!response) {
            setLoading(false);
            setError(true);
        } else {
            // @ts-ignore
            window.location = response.url;
        }
    });

    return (
        <GridRow column gutter={8} className={s.root}>
            <Logo className={s.logo} />
            {hasError && <NotificationInline text={i18n.login.error} type={NotificationTypeEnum.ERROR} />}
            <Controller
                name={'username'}
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Input
                        value={value}
                        onChange={onChange}
                        onSubmit={submit}
                        label={i18n.login.name}
                        error={!!error}
                    />
                )}
            />
            <Controller
                name={'password'}
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Input
                        value={value}
                        type={'password'}
                        onChange={onChange}
                        onSubmit={submit}
                        label={i18n.login.password}
                        error={!!error}
                    />
                )}
            />
            <Button isLoading={isLoading} onClick={submit}>
                {i18n.login.enter}
            </Button>
        </GridRow>
    );
};

export default Login;

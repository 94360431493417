import React from 'react';
import i18n from '../../utils/i18n';
import Input from '../../ui/Input';
import GridRow from '../../ui/GridRow';
import { Controller, Control } from 'react-hook-form';
import { IconEnum } from '../../ui/Icon';
import Details from '../../ui/Details/details';
import GridCol from '../../ui/GridCol';
import SeoKeywords from '../SeoKeywords';

interface ISeoProduct {
    control: Control;
    // setValue: UseFormSetValue<{ metaTitle: string; metaDescription: string }>;
}

const SeoProduct = ({ control }: ISeoProduct) => {
    // const brand = useWatch({ name: 'brand', control });
    // const productName = useWatch({ name: 'name', control });
    // const productSubName = useWatch({ name: 'sub_name', control });

    // useEffect(() => {
    //     if (productName) {
    //         const metaTitleArray = [brand?.value, productName, 'купить в Tex-Color Омск'];
    //         const metaDescriptionArray = [
    //             'Купить',
    //             brand?.value,
    //             productName,
    //             productSubName && '- ' + productSubName,
    //             'по лучшей цене в Омске в магазине Текс-Колор.',
    //             brand?.value,
    //             productName,
    //             'в наличии и под заказ с доставкой на объект.',
    //         ];
    //         setValue('metaTitle', metaTitleArray.join(' '));
    //         setValue('metaDescription', metaDescriptionArray.join(' '));
    //     }
    // }, [brand, productName]);

    return (
        <Details label={i18n.seo.name} icon={IconEnum.SEARCH}>
            <GridRow gutter={8}>
                <GridCol col={4}>
                    <Controller
                        name={'metaTitle'}
                        control={control}
                        rules={{ required: false }}
                        defaultValue={''}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Input
                                value={value}
                                onChange={onChange}
                                error={!!error}
                                errorMessage={error?.message}
                                label={i18n.seo.metaTitle}
                            />
                        )}
                    />
                </GridCol>
                <GridCol col={8}>
                    <Controller
                        name={'metaDescription'}
                        control={control}
                        rules={{ required: false }}
                        defaultValue={''}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Input
                                value={value}
                                onChange={onChange}
                                error={!!error}
                                errorMessage={error?.message}
                                label={i18n.seo.metaDescription}
                            />
                        )}
                    />
                </GridCol>
                <GridCol col={12}>
                    <SeoKeywords control={control} name={'keywords'} />
                </GridCol>
            </GridRow>
        </Details>
    );
};

export default SeoProduct;

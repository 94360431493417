import React from 'react';
import { Control, Controller, useFieldArray } from 'react-hook-form';
import GridRow from '../../ui/GridRow';
import GridCol from '../../ui/GridCol';
import Input from '../../ui/Input';
import i18n from '../../utils/i18n';
import DottedButton from '../../ui/DottedButton';
import s from './styles/seoKeywords.module.scss';
import Button from '../../ui/Button';
import Icon, { IconEnum } from '../../ui/Icon';
import { ButtonVariationEnum } from '../../ui/Button/types';
import InputMessage, { InputMessageTypes } from '../../ui/InputMessage/inputMessage';

interface ISeoKeywords {
    control: Control;
    name: string;
}

const SeoKeywords = ({ control, name }: ISeoKeywords) => {
    const { fields, append, remove } = useFieldArray({
        control,
        keyName: 'formId',
        name,
    });
    return (
        <GridRow gutter={8}>
            <GridCol col={12}>
                <p className={s.title}>{i18n.seo.keywords}</p>
            </GridCol>
            {fields?.map((item, idx) => (
                <GridCol key={item.formId} col={3} className={s.fieldWrapper}>
                    <Controller
                        name={`${name}.${idx}`}
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Input
                                value={value}
                                onChange={onChange}
                                small
                                className={s.inputField}
                                label={i18n.general.name}
                                error={!!error}
                            />
                        )}
                    />
                    <Button variation={ButtonVariationEnum.SIMPLE} isSmall onClick={() => remove(idx)}>
                        <Icon icon={IconEnum.DELETE} className={s.icon} />
                    </Button>
                </GridCol>
            ))}
            <DottedButton onClick={() => append('')}>{i18n.general.add}</DottedButton>
            <GridCol col={12}>
                <InputMessage message={i18n.seo.keywordsHint} type={InputMessageTypes.INFO} />
            </GridCol>
        </GridRow>
    );
};

export default SeoKeywords;

import React from 'react';
import { useFetchGet } from '../../utils/fetch/useFetchGet';
import { IProductShort } from '../ProductAll/types';
import { paths, pathsApi } from '../../router/paths';
import i18n from '../../utils/i18n';
import Body from '../../ui/Body/body';
import GridCol from '../../ui/GridCol';
import ButtonLink from '../../ui/Button/buttonLink';
import { ButtonVariationEnum } from '../../ui/Button/types';
import GridRow from '../../ui/GridRow';
import Table from '../../ui/Table';
import { TagEnum } from '../../ui/Tag/types';
import { boolToReadable } from '../../utils/boolUtil';
import Tag from '../../ui/Tag';

const BrandAll = () => {
    const { data } = useFetchGet<Array<IProductShort>>({ path: pathsApi.brandAll });

    return (
        <Body title={i18n.menu.brand} isLoading={!data}>
            <GridRow gutter={8}>
                <GridCol col={12}>
                    <ButtonLink href={paths.brandNew} variation={ButtonVariationEnum.SOFT}>
                        {i18n.menu.brandNew}
                    </ButtonLink>
                </GridCol>
                <GridCol col={12}>
                    <Table
                        widths={[30, 10]}
                        headers={[i18n.general.brand, i18n.general.active]}
                        data={data?.map((item) => ({
                            items: [
                                item.name,
                                <Tag type={!!item.deletedAt ? TagEnum.RED : TagEnum.GREEN}>
                                    {boolToReadable(!item.deletedAt)}
                                </Tag>,
                            ],
                            id: item.id,
                            link: paths.brand + item.id,
                        }))}
                    />
                </GridCol>
            </GridRow>
        </Body>
    );
};

export default BrandAll;

import React from 'react';
import Body from '../../ui/Body/body';
import GridRow from '../../ui/GridRow';
import { routes } from '../../router/paths';
import MenuTile from '../../ui/MenuTile';
import { IconEnum } from '../../ui/Icon';
import s from './styles/homepage.module.scss';
import { GridJustifyEnum } from '../../ui/GridRow/GridRow';

const Homepage = () => {
    const tiles = routes.filter((item) => item.homepage);

    return (
        <Body>
            <div className={s.wrapper}>
                <GridRow isFlex gutter={4} justifyRow={GridJustifyEnum.CENTER}>
                    {tiles.map((item) => (
                        <MenuTile
                            key={item.path}
                            path={item.path}
                            icon={item.icon as IconEnum}
                            label={item.label as string}
                        />
                    ))}
                </GridRow>
            </div>
        </Body>
    );
};

export default Homepage;

import React from 'react';
import { useFieldArray, Control, Controller, useFormState } from 'react-hook-form';
import i18n from '../../utils/i18n';
import DottedButton from '../../ui/DottedButton';
import GridRow from '../../ui/GridRow';
import Input from '../../ui/Input';
import s from './styles/productTechDescription.module.scss';
import { ButtonVariationEnum } from '../../ui/Button/types';
import Button from '../../ui/Button';
import Icon, { IconEnum } from '../../ui/Icon';
import Details from '../../ui/Details/details';

interface IProductTechDescription {
    control: Control;
    name: string;
}

const ProductTechDescription = ({ control, name }: IProductTechDescription) => {
    const { errors } = useFormState({ control });
    const { fields, append, remove } = useFieldArray({
        control,
        keyName: 'formId',
        name,
    });
    return (
        <Details label={i18n.product.techDescription} icon={IconEnum.SETTING2} error={!!errors?.[name]}>
            <GridRow gutter={8}>
                {fields.map((item, idx) => {
                    return (
                        <GridRow key={item.formId} gutter={4} className={s.itemRow}>
                            <Controller
                                name={`${name}.${idx}.name`}
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <Input
                                        value={value}
                                        onChange={onChange}
                                        small
                                        label={i18n.general.name}
                                        error={!!error}
                                    />
                                )}
                            />
                            <div className={s.minus}>{':'}</div>
                            <Controller
                                name={`${name}.${idx}.value`}
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <Input
                                        value={value}
                                        onChange={onChange}
                                        small
                                        label={i18n.general.value}
                                        error={!!error}
                                    />
                                )}
                            />
                            <Button
                                variation={ButtonVariationEnum.SIMPLE}
                                isSmall
                                onClick={() => remove(idx)}
                                title={i18n.general.delete}
                            >
                                <Icon icon={IconEnum.DELETE} className={s.icon} />
                            </Button>
                        </GridRow>
                    );
                })}
                <DottedButton className={s.addButton} onClick={() => append({})}>
                    {i18n.general.add}
                </DottedButton>
            </GridRow>
        </Details>
    );
};

export default ProductTechDescription;

import React, { useState, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useFetchGet } from '../../utils/fetch/useFetchGet';
import { pathsApi } from '../../router/paths';
import i18n from '../../utils/i18n';
import Body from '../../ui/Body/body';
import GridRow from '../../ui/GridRow';
import { IPriceAttrGroup } from './types';
import GridCol from '../../ui/GridCol';
import Details from '../../ui/Details/details';
import Card from '../../ui/Card';
import Button from '../../ui/Button';
import { ButtonVariationEnum } from '../../ui/Button/types';
import Popup from '../../ui/Popup';
import Input from '../../ui/Input';
import { PopupSize } from '../../ui/Popup/Popup';
import { FetchApi } from '../../utils/fetch/fetchApi';

const PriceAttr = () => {
    const { data, setData } = useFetchGet<Array<IPriceAttrGroup>>({ path: pathsApi.priceAttrAll });
    const [isOpen, handleOpen] = useState<false | number>(false);
    const [isLoading, setLoading] = useState(false);

    const { control, reset, handleSubmit } = useForm<{ value: string }>();

    const handleSave = handleSubmit(async (data) => {
        const response = await FetchApi.post<Array<IPriceAttrGroup>>(pathsApi.priceAttrSave, {
            value: data.value,
            group: isOpen,
        });

        if (response) {
            setData(response);
            handleOpen(false);
            reset();
        }
        setLoading(false);
    });

    const dataMemo = useMemo(() => {
        return data?.map((group) => (
            <Details key={group.id} label={group.value}>
                <GridRow gutter={4}>
                    <GridCol col={12}>
                        <Button isSmall variation={ButtonVariationEnum.SOFT} onClick={() => handleOpen(group.id)}>
                            {i18n.price.newAttr}
                        </Button>
                    </GridCol>
                    {group.items
                        .sort((a, b) =>
                            Number.isInteger(a.order) && Number.isInteger(b.order)
                                ? (a.order as number) - (b.order as number)
                                : new Intl.Collator('ru').compare(a.value, b.value),
                        )
                        .map((item) => (
                            <GridCol col={3} key={item.id}>
                                <Card>{item.value}</Card>
                            </GridCol>
                        ))}
                </GridRow>
            </Details>
        ));
    }, [data]);

    return (
        <Body title={i18n.menu.priceAttr} isLoading={!data}>
            <div>{dataMemo}</div>
            {isOpen !== false && (
                <Popup size={PopupSize.s} close={() => handleOpen(false)} title={i18n.price.newAttr}>
                    <GridRow gutter={8} column>
                        <Controller
                            control={control}
                            name={'value'}
                            rules={{ required: true }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <Input
                                    value={value}
                                    onChange={onChange}
                                    error={!!error}
                                    errorMessage={error?.message}
                                    label={i18n.general.name}
                                />
                            )}
                        />
                        <Button onClick={handleSave} isLoading={isLoading}>
                            {i18n.general.save}
                        </Button>
                    </GridRow>
                </Popup>
            )}
        </Body>
    );
};

export default PriceAttr;

import React, { useState, useEffect, ReactNode, createContext, useContext } from 'react';
import Loading from '../../ui/Loading';
import { FetchApi } from '../../utils/fetch/fetchApi';
import { pathsApi } from '../../router/paths';
import { ICategoryFilters, IConfig } from './types';

interface IConfigContext {
    config: IConfig;
    getCategoryFilters: () => Promise<void>;
}

const ConfigContext = createContext<IConfigContext>({} as IConfigContext);

const Config = ({ children }: { children?: ReactNode }) => {
    const [config, setConfig] = useState<IConfig | null>(null);

    const getConfig = async () => {
        const response = await FetchApi.get<IConfig>(pathsApi.config);
        if (response) {
            setConfig(response);
        }
    };

    const getCategoryFilters = async () => {
        const response = await FetchApi.get<Array<ICategoryFilters>>(pathsApi.categoryFilters);
        if (response) {
            setConfig({ ...(config as IConfig), categoryFilters: response });
        }
    };

    useEffect(() => {
        getConfig();
    }, []);

    if (!config) return <Loading />;

    return <ConfigContext.Provider value={{ config, getCategoryFilters }}>{children}</ConfigContext.Provider>;
};

export const useConfig = () => useContext(ConfigContext);

export default Config;

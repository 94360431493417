import React, { useState } from 'react';
import { Control, Controller, useFieldArray, useFormState, useWatch } from 'react-hook-form';
import Details from '../../ui/Details/details';
import i18n from '../../utils/i18n';
import { IconEnum } from '../../ui/Icon';
import GridRow from '../../ui/GridRow';
import s from '../ImagesUpload/styles/imagesUpload.module.scss';
import Card from '../../ui/Card';
import Input from '../../ui/Input';
import DottedButton from '../../ui/DottedButton';
import ImageUploadPopUp from '../ImagesUpload/modules/imageUploadPopUp';
import { FileUploadType } from '../ImagesUpload/types';
import { filesNames } from '../../utils/fetch/filesNames';
import InputMessage, { InputMessageTypes } from '../../ui/InputMessage/inputMessage';
import TextItem from '../../ui/TextItem/textItem';
import DeleteButton from '../ImagesUpload/modules/deleteButton';
import { useConfig } from '../Config/config';

interface IFileUpload {
    control: Control;
    name: string;
    defaultAlt?: string;
}

const FileUpload = ({ control, name, defaultAlt = '' }: IFileUpload) => {
    const { config } = useConfig();
    const brand = useWatch({ name: 'brand', control });
    const brandFromConfig = brand && config.brands.find((item) => item.id === brand.id);
    const productName = useWatch({ name: 'name', control });
    const { errors } = useFormState({ control });

    const [isPopupOpen, togglePopUp] = useState<boolean>(false);
    const { fields, append, remove } = useFieldArray({
        control,
        keyName: 'formId',
        name,
    });

    const handleSuccessUpload = (fileName: string, size: number) => {
        append({ src: fileName, size });
    };

    return (
        <Details label={i18n.product.docList} icon={IconEnum.DOCS} error={!!errors?.[name]}>
            {!(brand && name) && <InputMessage type={InputMessageTypes.WARNING} message={i18n.doc.fillNameAndBrand} />}
            <GridRow className={s.rootDocs} gutter={8}>
                {(fields as Array<Record<string, any>>)?.map((item, idx) => (
                    <Card key={item.formId}>
                        <GridRow column gutter={8}>
                            <TextItem label={i18n.images.fileName} text={item.src} />
                            <Controller
                                name={`${name}.${idx}.name`}
                                control={control}
                                rules={{ required: true }}
                                defaultValue={defaultAlt}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <Input value={value} onChange={onChange} label={i18n.doc.title} error={!!error} />
                                )}
                            />
                            <DeleteButton handleDelete={() => remove(idx)} type={FileUploadType.DOC} src={item.src} />
                        </GridRow>
                    </Card>
                ))}
                <DottedButton className={s.addButton} onClick={() => togglePopUp(true)}>
                    {i18n.images.upload + i18n.doc.name}
                </DottedButton>
            </GridRow>
            {isPopupOpen && (
                <ImageUploadPopUp
                    closePopUp={() => togglePopUp(false)}
                    type={FileUploadType.DOC}
                    handleSuccess={handleSuccessUpload}
                    titleName={i18n.doc.name}
                    fileName={filesNames({ brand: brandFromConfig?.alias, name: productName })}
                />
            )}
        </Details>
    );
};

export default FileUpload;

import React, { useState } from 'react';
import { Control, useFieldArray } from 'react-hook-form';
import Details from '../../ui/Details/details';
import i18n from '../../utils/i18n';
import Icon, { IconEnum } from '../../ui/Icon';
import Table from '../../ui/Table';
import { IProductShort } from '../../pages/ProductAll/types';
import Button from '../../ui/Button';
import { ButtonVariationEnum } from '../../ui/Button/types';
import s from './styles/relatedProductsEdit.module.scss';
import GridRow from '../../ui/GridRow';
import Popup from '../../ui/Popup';
import { PopupSize } from '../../ui/Popup/Popup';
import ProductListing from './modules/productListing';

interface IRelatedProductsEdit {
    control: Control;
}

const RelatedProductsEdit = ({ control }: IRelatedProductsEdit) => {
    const [isOpen, handlePopupOpen] = useState<boolean>(false);
    const { fields, append, remove } = useFieldArray({
        control,
        keyName: 'formId',
        name: 'relatedProducts',
    });

    return (
        <Details label={i18n.product.relatedProducts} icon={IconEnum.DOCS}>
            <GridRow column gutter={12}>
                <Table
                    widths={[10, 30, 55, 5]}
                    headers={[i18n.general.brand, i18n.general.name, i18n.general.category]}
                    data={(fields as unknown as Array<IProductShort & { formId: string }>)?.map((item, idx) => ({
                        items: [
                            item.brand?.name,
                            item.name,
                            item.category?.name,
                            <Button variation={ButtonVariationEnum.SIMPLE}>
                                <Icon icon={IconEnum.DELETE} className={s.icon} onClick={() => remove(idx)} />{' '}
                            </Button>,
                        ],
                        id: item.formId,
                    }))}
                />
                <Button variation={ButtonVariationEnum.SOFT} onClick={() => handlePopupOpen(true)}>
                    {i18n.general.add}
                </Button>
            </GridRow>
            {isOpen && (
                <Popup size={PopupSize.l} title={i18n.product.relatedProducts} close={() => handlePopupOpen(false)}>
                    <ProductListing
                        selectProduct={(product) => {
                            append(product);
                            handlePopupOpen(false);
                        }}
                    />
                </Popup>
            )}
        </Details>
    );
};

export default RelatedProductsEdit;
